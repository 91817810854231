import { useMemo, useRef, useState } from "react"
import styled from "styled-components"

import {
  OnDatesChangeProps,
  START_DATE,
  useDatepicker,
  UseDatepickerProps,
} from "@datepicker-react/hooks"
import { Popper, PopperProps } from "@material-ui/core"
import {
  addDays,
  endOfDay,
  isFuture,
  startOfDay,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from "date-fns"
import { useMediaQuery } from "usehooks-ts"

import { Z_INDEX_ABOVE_MODAL } from "src/constants/zindex"
import { getPortalRoot } from "src/ui/BaseModalDialog/baseModalDialogUtils"
import { DateRangePickerContext } from "src/ui/DateRangePicker/DateRangePickerContext"

import {
  BREAKPOINT_CALENDAR_FLOATING,
  Calendar,
  CalendarProps,
  PresetKey,
} from "./Calendar"

export type TDateRange = {
  startDate: Date
  endDate: Date
}

export type DateRangePickerContainerProps = {
  startDate: Date | null
  endDate: Date | null
  onDateRangeChange: (dr: TDateRange) => void
  dateRangeProps?: Omit<
    UseDatepickerProps,
    | "startDate"
    | "endDate"
    | "focusedInput"
    | "onDatesChange"
    | "maxBookingDate"
  >
  defaultPresetKey?: PresetKey
  calendarPlacement?: PopperProps["placement"]
  behavior: "date" | "dateRange"
  renderButton: (p: {
    onClick: () => void
    state: Pick<OnDatesChangeProps, "endDate" | "startDate">
  }) => JSX.Element
  hidePresets?: CalendarProps["hidePresets"]
  hideActionbar?: boolean
  onSelectPreset?: ({
    presetKey,
    clearSelection,
  }: {
    presetKey: PresetKey
    clearSelection?: boolean
  }) => void
  // Adjusts the startDate to ensure the date range is a multiple of the given interval
  intervalMs?: number
  maxSelectableDays?: number
}

export function DateRangePickerContainer({
  startDate,
  endDate,
  onDateRangeChange,
  dateRangeProps,
  defaultPresetKey = "MONTH",
  calendarPlacement = "top",
  behavior,
  renderButton,
  hidePresets,
  hideActionbar,
  onSelectPreset,
  intervalMs,
  maxSelectableDays = 365 * 2,
}: DateRangePickerContainerProps) {
  const [state, setState] = useState<OnDatesChangeProps>({
    startDate: startDate ? startOfDay(startDate) : null,
    endDate: endDate ? endOfDay(endDate) : null,
    focusedInput: "startDate",
  })

  const [showCalendar, setShowCalendar] = useState(false)
  const dateFieldRef = useRef<HTMLDivElement>(null)

  const isFloating = useMediaQuery(
    `(min-width: ${BREAKPOINT_CALENDAR_FLOATING})`
  )
  const placement = isFloating ? calendarPlacement : undefined

  const maxBookingDate = useMemo(() => {
    if (state.startDate && state.endDate) {
      return new Date()
    }

    const maxBookingDate = addDays(
      state.startDate ?? new Date(),
      maxSelectableDays
    )

    if (isFuture(maxBookingDate)) {
      return new Date()
    }

    return maxBookingDate
  }, [state, maxSelectableDays])

  const {
    activeMonths,
    firstDayOfWeek,
    focusedDate,
    goToNextMonths,
    goToPreviousMonths,
    goToDate,
    isDateBlocked,
    isDateFocused,
    isDateHovered,
    isDateSelected,
    isStartDate,
    isEndDate,
    isFirstOrLastSelectedDate,
    onDateFocus,
    onDateHover,
    onDateSelect,
  } = useDatepicker({
    startDate: state.startDate,
    endDate: state.endDate,
    focusedInput: state.focusedInput,
    onDatesChange: handleDateChange,
    maxBookingDate,
    changeActiveMonthOnSelect: false,
    ...dateRangeProps,
  })

  /**
   * Pushes the startDate back so that the dateRange startDate-endDate is a multiple of the given interval
   * @param startDate - The start date to push back
   * @param endDate - The end date
   * @param interval - The interval in milliseconds
   * @returns object with the new startDate and endDate
   */
  function updateDateRangeToMatchInterval(
    startDate: Date,
    endDate: Date,
    intervalMs: number
  ) {
    const difference =
      startOfDay(endDate).getTime() - startOfDay(startDate).getTime()
    const ratio = Math.ceil(difference / intervalMs)
    return {
      startDate: new Date(startOfDay(endDate).getTime() - ratio * intervalMs),
      endDate,
    }
  }

  function handleDateChange(data: OnDatesChangeProps) {
    if (!data.startDate) {
      // Nothing to do, we're clearing the date range
      setState({
        startDate: null,
        endDate: null,
        focusedInput: null,
      })
      return
    }

    // Behavior is date, and not dateRange, so we just need a single date
    if (data.startDate && behavior === "date") {
      const transformedStartDate = startOfDay(data.startDate)
      setState({
        startDate: transformedStartDate,
        endDate: transformedStartDate,
        focusedInput: START_DATE,
      })
      onDateRangeChange({
        startDate: transformedStartDate,
        endDate: transformedStartDate,
      })
      setShowCalendar(false)
      return
    }

    // We're in dateRange mode, but still in process of selecting the endDate, just pass on the state
    if (!data.endDate || !!data.focusedInput) {
      setState({
        ...data,
        endDate: null,
      })
      return
    }

    // We're in dateRange mode, and have both startDate and endDate, so we can finalize the dates
    const { startDate, endDate } = !!intervalMs
      ? updateDateRangeToMatchInterval(data.startDate, data.endDate, intervalMs)
      : {
          startDate: startOfDay(data.startDate),
          endDate: endOfDay(data.endDate),
        }

    setState({
      startDate,
      endDate,
      focusedInput: START_DATE,
    })
    onDateRangeChange({
      startDate,
      endDate,
    })
    setShowCalendar(false)
  }

  function handleSelectPreset({
    presetKey,
    clearSelection,
  }: {
    presetKey: PresetKey
    clearSelection?: boolean
  }) {
    let startDate: Date
    let endDate: Date

    switch (presetKey) {
      case "DAY":
        startDate = startOfDay(subDays(new Date(), 1))
        endDate = endOfDay(new Date())
        break
      case "WEEK":
        startDate = startOfDay(subWeeks(new Date(), 1))
        endDate = endOfDay(new Date())
        break
      case "MONTH":
        startDate = startOfDay(subMonths(new Date(), 1))
        endDate = endOfDay(new Date())
        break
      case "YEAR":
        startDate = startOfDay(subYears(new Date(), 1))
        endDate = endOfDay(new Date())
        break
      default:
        throw Error("Unknown date range preset value:", presetKey)
    }

    setState((state) => ({
      ...state,
      startDate: clearSelection ? null : startDate,
      endDate: clearSelection ? null : endDate,
      focusedInput: START_DATE,
    }))

    goToDate(startDate)

    onDateRangeChange({ startDate, endDate })

    onSelectPreset?.({ presetKey, clearSelection })
  }

  const handleDateFieldClick = () => {
    setShowCalendar((open) => !open)
  }

  function handleCloseCalendar() {
    setShowCalendar(false)
  }

  return (
    <DateRangePickerContext.Provider
      value={{
        focusedDate,
        isDateFocused,
        isDateSelected,
        isDateHovered,
        isDateBlocked,
        isFirstOrLastSelectedDate,
        isStartDate,
        isEndDate,
        onDateSelect,
        onDateFocus,
        onDateHover,
        goToPreviousMonths,
        goToNextMonths,
      }}
    >
      <Box ref={dateFieldRef}>
        {renderButton && renderButton({ onClick: handleDateFieldClick, state })}
        <MPopper
          open={!!showCalendar}
          anchorEl={dateFieldRef.current}
          placement={placement}
          container={getPortalRoot}
        >
          <Calendar
            open={showCalendar}
            onClose={handleCloseCalendar}
            onSelectPreset={handleSelectPreset}
            firstDayOfWeek={firstDayOfWeek}
            activeMonths={activeMonths}
            state={state}
            defaultPresetKey={defaultPresetKey}
            hidePresets={hidePresets}
            hideActionBar={hideActionbar}
            dayBackgroundColor={behavior === "date" ? "transparent" : undefined}
          />
        </MPopper>
        {/* This backdrop element is needed to detect click events outside the
        rendered calendar; MUIs ClickAwayListener doesn't work. */}
        {!!showCalendar && <Backdrop onClick={handleCloseCalendar}></Backdrop>}
      </Box>
    </DateRangePickerContext.Provider>
  )
}

const Box = styled.div`
  position: relative;
`

const Backdrop = styled.div`
  position: fixed;
  inset: 0;
  background-color: transparent;
`

const MPopper = styled(Popper)`
  z-index: ${Z_INDEX_ABOVE_MODAL};
`
